#resource-component {
    .my-container {
        /*border: 2px solid yellow;*/
        padding:0 220px 0 256px;
        overflow: hidden;
    }
    .left,
    .main,
    .right{
        position: relative;
        float: left;
        min-height: 130px;
        /*border: 2px solid black;*/
    }
    .left{
        margin-left: -100%;
        left: -256px;
        width: 256px;
    }
    .right{
        margin-left: -220px;
        right: -220px;
        width: 220px;
    }
    .main{
        width: 100%;
    }
    .card #nav {
        width: 256px;
        height: 500px;
        float: left;
    }
    .card #select-version {
        width: 200px;
        height: 30px;
        margin-right: 20px;
        float: left;
    }
    .card #item-content {
        width: 220px;
        min-height: 100px;
        position: fixed;
        margin-top: 150px;
    }
    .card #file-content {
        min-width: 400px;
        min-height: 450px;
        float: left;
        margin-top: 20px;
        margin-left: 20px;
    }
    .card #loading {
        width: 50px;
        height: 50px;
        position: fixed;
        margin-left: 300px;
        margin-top: 200px;
    }
}
