#dashuaibi-component {
  .config_choice {white-space:nowrap;}
  .checkbox label .toggle,.checkbox-inline .toggle{margin-left:-20px;margin-right:5px}
  .toggle{position:relative;overflow:hidden}
  .toggle input[type=checkbox]{display:none}
  .toggle-group{position:absolute;width:200%;top:0;bottom:0;left:0;transition:left .35s;-webkit-transition:left .35s;-moz-user-select:none;-webkit-user-select:none}
  .toggle.off .toggle-group{left:-100%}
  .toggle-on{position:absolute;top:0;bottom:0;left:0;right:50%;margin:0;border:0;border-radius:0}
  .toggle-off{position:absolute;top:0;bottom:0;left:50%;right:0;margin:0;border:0;border-radius:0}
  .toggle-handle{position:relative;margin:0 auto;padding-top:0;padding-bottom:0;height:100%;width:0;border-width:0 1px}
  .toggle.btn{/* min-width:59px; *//* min-height:34px */}
  .toggle-on.btn{padding-right:24px}
  .toggle-off.btn{padding-left:24px}
  .toggle.btn-lg{min-width:79px;min-height:45px}
  .toggle-on.btn-lg{padding-right:31px}
  .toggle-off.btn-lg{padding-left:31px}
  .toggle-handle.btn-lg{width:40px}
  .toggle.btn-sm{min-width:50px;min-height:30px}
  .toggle-on.btn-sm{padding-right:20px}
  .toggle-off.btn-sm{padding-left:20px}
  .toggle.btn-xs{min-width:60px;min-height:30px;position: absolute;left: 190px}
  .toggle-on.btn-xs{padding-right:12px}
  .toggle-off.btn-xs{padding-left:12px}
  .list-group-item:hover{background:#79797931;}
  li:hover{background:#79797975;}
  .clicked{background:#79797975;}
  .expand1,.expand2,.expand3,.expand4{height:37px;margin-bottom: 15px}
  .btn-unlock:hover::before{content:"| UNLOCK |";color: red;;font-weight: 800 }
}
