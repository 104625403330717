#login-component {
    .card #phone-code-input {
        display: inline-block;
        min-width: 50px;
    }
    .card #phone-code-button {
        display: inline-block;
    }
    .card #phone-code-tip {
        margin-top: 10px;
        margin-bottom: -10px;
        margin-left: 5px;
        font-size: 12px;
    }
}
